import { Arrow } from "@/assets/Icons";
import { testimonials } from "@/data/testimonials";
import Image from "next/image";
import Btn from "../uikit/Btn";
import SectionHeaning from "../uikit/SectionHeaning";

function Testimonials() {
  return (
    <section className="relative">
      <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 ">
        <SectionHeaning
          title={" Happy Testimonials"}
          subTitle={"Client Stories"}
          description={
            " Read on to explore how Youcad's commitment to excellence has left a lasting impact on the design community."
          }
        />
        <div className="mb-8 gap-5 py-4 [column-count:1] md:mb-12 md:[column-count:2] lg:mb-16 lg:[column-count:3]">
          {testimonials.map((e, i) => (
            <div
              key={i}
              className="mb-6 gap-6 overflow-hidden rounded-2xl border border-solid border-[#7e7e7e] bg-white p-8"
            >
              <div className="mb-4 flex flex-row ">
                <Image
                  width={100}
                  height={100}
                  src={e.user_img}
                  alt=""
                  className="mr-4 inline-block h-16 w-16 rounded-full object-cover"
                />
                <div className="flex flex-col">
                  <h3 className="text-base font-semibold">{e.name}</h3>
                  <p className="text-sm text-[#636262]">{e.job}</p>
                </div>
              </div>
              <p className="mb-4 text-sm text-[#636262]">{e.testimony}</p>
              <div className="flex">
                {Array(e.stars)
                  .fill(" ")
                  .map((star, index) => (
                    <Image
                      width={100}
                      height={100}
                      key={index}
                      src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63904f663019b0ce62f8d5ba_Vector.svg"
                      alt=""
                      className="mr-1.5 inline-block w-4 flex-none"
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex relative flex-col items-center justify-center">
          <Btn text={"Start Shopping"} link={"/softwares"} />
          <div className="-scale-x-100  mr-48 inline-flex h-10 w-10 -rotate-12 text-[#EC1C1C]">
            <Arrow />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
