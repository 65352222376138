


export const testimonials = [
    {
        user_img:"https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name:"John Smith",
        job:"Architect",
        testimony:'“I can\'t express how impressed I am with Youcad\'s budget-friendly Autodesk solutions. As an architect, precision is everything, and their software has not only met but exceeded my expectations. It\'s a game-changer!"',
        stars:5
    },
    {
        user_img:"https://plus.unsplash.com/premium_photo-1681489930334-b0d26fdb9ed8?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name:"Emma Rodriguez",
        job:"Interior Designer",
        testimony:' "Youcad\'s customized Autodesk solutions have transformed the way I approach interior design projects. The affordability factor is a huge win without compromising on innovation. My designs have reached a new level of excellence!"',
        stars:5
    },
    {
        user_img:"https://assets.website-files.com/63904f663019b0d8edf8d57c/63905430069fb027f83abb71_Ellipse-3.jpg",
        name:"Michael Chen",
        job:"Civil Engineer",
        testimony:'"Being in the field of civil engineering, precision and innovation are non-negotiable. Youcad\'s budget-friendly Autodesk solutions have proven to be my go-to. It\'s like having a customized tool for every project, and the cost-effectiveness is a huge bonus."',
        stars:5
    },
    {
        user_img:"https://assets.website-files.com/63904f663019b0d8edf8d57c/63919c4a64bf2667d0775e71_Ellipse%2027.png",
        name:"Sarah Johnson",
        job:"Landscape Architect",
        testimony:'“Youcad has made my landscape designs come to life with their affordable Autodesk solutions. The precision and customization options are unparalleled. I\'ve found my secret weapon for stunning and budget-friendly projects!"',
        stars:5
    },
    {
        user_img:"https://images.unsplash.com/photo-1651684215020-f7a5b6610f23?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name:"Carlos Gomez",
        job:"Industrial Designer",
        testimony:' "In the world of industrial design, innovation is key. Youcad\'s Autodesk solutions not only fit my budget but have opened up new possibilities for creativity. It\'s like having a personal design assistant that doesn\'t break the bank!"',
        stars:5
    },
    {
        user_img:"https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?q=80&w=1376&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name:"Olivia White",
        job:"Structural Engineer",
        testimony:'"Youcad has made a significant impact on the way I approach structural engineering projects. The affordability of their Autodesk solutions is a breath of fresh air, and the precision it offers is unmatched. Truly a game-changer!"',
        stars:5
    },

]



